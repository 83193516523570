<template>
  <main class="flex-shrink-0">

    <router-link to="/home" class="p-2 btn btn-sm text-secondary align-bottom" aria-current="page"><i class="fa-solid fa-arrow-left fa-2xs"></i> Home</router-link>


    <br>

    <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary">
      <div class="col-md-6 p-lg-5 mx-auto my-5">
        <h1 class="display-3 fw-light">Online Tools</h1>
        <h3 class="fw-normal text-muted mb-3">(kostenlos)</h3>
      </div>
      <div class="product-device shadow-sm d-none d-md-block"></div>
      <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
    </div>



    <br>
    <div class="container my-5">
      <div class="p-5 text-center bg-body-secondary rounded-3">
        <i class="fas fa-flag-checkered fa-3x"></i>
        <h1 class="text-body-emphasis">ACC Helper</h1>
        <p class="col-lg-8 mx-auto fs-5 text-muted">
          Einfaches Tool um die Setups in Assetto Corsa Competitione, besser zu verstehen und anpassen zu können.
        </p>
        <div class="d-inline-flex gap-2 mb-5">         
          <a href="https://acchelper.at" class="p-2 btn btn-primary btn-lg rounded-pill" aria-current="page">Zeig</a><br>
        </div>
      </div>
    </div>
    <br>
    <div class="container my-5">
      <div class="p-5 text-center bg-body-secondary rounded-3">
        <i class="fas fa-coins fa-3x"></i>
        <h1 class="text-body-emphasis">Coincounter</h1>
        <p class="col-lg-8 mx-auto fs-5 text-muted">
          Einfaches Tool um die einzelnen Scheinen zu summieren, gerne benutzt für Sparvereinzählungen am Monitor oder Tablet. (Aktuell nicht verwendbar)
        </p>
        <div class="d-inline-flex gap-2 mb-5">         
          <a href="https://coincounter.sidanet.at" class="p-2 btn btn-primary btn-lg rounded-pill" aria-current="page">Zeig</a><br>
        </div>
      </div>
    </div>
    <br>




  </main>


</template>


<script>
export default {
  computed: {
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.closeMenu();
  },
  methods: {
    closeMenu() {
      var toggler = document.getElementById('navbarCollapse');
      try {
        toggler.classList.remove("hide");
      } catch (error) {
        toggler.classList.remove("show");
      } 

    },
  }
};
</script>