<template>
    <main>
      
      <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary">
        <div class="col-md-6 p-lg-5 mx-auto my-5">
            <h1 class="display-3 fw-light">Impressum</h1>
            <h3 class="fw-normal text-muted mb-3">von Sidanet.at</h3>
            <hr>
        </div>
        <div class="product-device shadow-sm d-none d-md-block"></div>
        <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
      </div>


      <!-- <h4 class="subtitle is-2">Impressum</h4> -->
      <div>




      <h4>Informationen laut §63 Gewerbeordnung, Offenlegungspflicht laut §25 Mediengesetz &amp; Informationspflicht laut §5 E-Commerce Gesetz.</h4>

      <p><br>Roland Hackl<br>Rohrbacherstraße 17 &nbsp;<br>4154 Kollerschlag &nbsp;<br>Österreich</p>

      <p><strong>UID-Nummer:</strong>&nbsp;ATU74390223</p>

      <p></p>

      <p>Mitglied in der <strong>Wirtschaftskammer Österreich</strong><strong>Aufsichtsbehörde/Gewerbebehörde:</strong>&nbsp;BH Rohrbach</p>

      <p><strong>Tel.:</strong>       &nbsp; (+43) 660 6036399<br><strong>E-Mail:</strong>&nbsp;   <a href="mailto:office@sidanet.at">office@sidanet.at</a></p>

      <p><strong>Unternehmensgegenstand:</strong>&nbsp;Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik<br></p>






      </div>
    </main>
</template>



<script>
export default {
  computed: {
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.closeMenu();
  },
  methods: {
    closeMenu() {
      var toggler = document.getElementById('navbarCollapse');
      try {
        toggler.classList.remove("hide");
      } catch (error) {
        toggler.classList.remove("show");
      } 

    },
  }
};
</script>