<template>
    <main>
      <router-link to="/home" class="p-2 btn btn-sm text-secondary align-bottom" aria-current="page"><i class="fa-solid fa-arrow-left fa-2xs"></i> Home </router-link>
      <br>
      <hr>
      <div class="container my-5">
        <div class="p-5 text-center bg-body-tertiary rounded-3">
          <i class="fas fa-store fa-3x"></i>
          <h1 class="text-body-emphasis">Online Basar</h1>
          <p class="col-lg-8 mx-auto fs-5 text-muted">
            Dieses Onlineportal hilft dabei den Basar organisiert und übersichtlich zu halten.
            Übernimmt den großteil der Kommunikation, stellt Barcodes zur verfügung, hilft bei Auswertungen und so viel mehr.
          </p>
          <div class="d-inline-flex gap-2 mb-5">         
            <a href="https://preview.basar.sidanet.at" class="p-2 btn btn-primary btn-lg rounded-pill" aria-current="page">Zur Online Vorschau</a><br>
            <a href="tel: 00436036399" class="btn btn-outline-secondary btn-lg rounded-pill">Anrufen</a>
          </div>
        </div>
      </div>
  </main>
</template>


<script>
export default {
  computed: {
    isLoggedin() {
      return this.$store.getters.isAuthenticated;
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  methods: {
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style>
.col {
  margin-bottom: 2rem;
}
</style>