<template>
    <main>
      

        
        <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary">
        <div class="col-md-6 p-lg-5 mx-auto my-5">
            <h1 class="display-3 fw-light">Datenschutz</h1>
            <h3 class="fw-normal text-muted mb-3">Datenschutzerklärung</h3>
            <hr>
        </div>
        <div class="product-device shadow-sm d-none d-md-block"></div>
        <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
        </div>




      <!-- <h4 class="subtitle is-2">Datenschutz</h4> -->
      <div>

      <!-- <h1 class="adsimple-112110023">Datenschutzerklärung</h1> -->
      <h2 id="einleitung-ueberblick" class="adsimple-112110023">Einleitung und Überblick</h2>
      <p>Wir haben diese Datenschutzerklärung (Fassung 30.08.2022-112110023) verfasst, um Ihnen gemäß der Vorgaben der <a class="adsimple-112110023" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=112110023#d1e2269-1-1" target="_blank" rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen
          Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche – und die von
          uns beauftragten Auftragsverarbeiter (z. B. Provider) – verarbeiten, zukünftig verarbeiten werden und
          welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu
          verstehen.<br>
          <strong class="adsimple-112110023">Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über
          Sie verarbeiten.</p>
      <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese
          Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich
          beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong class="adsimple-112110023">Begriffe leserfreundlich erklärt</strong>, Links zu weiterführenden Informationen
          geboten und <strong class="adsimple-112110023">Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in
          klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten
          verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man
          möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard
          sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ
          und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br>
          Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte
          verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten
          anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
      <h2 id="anwendungsbereich" class="adsimple-112110023">Anwendungsbereich</h2>
      <p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für
          alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit
          personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name,
          E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür,
          dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der
          Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
      <ul class="adsimple-112110023">
          <li class="adsimple-112110023">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
          <li class="adsimple-112110023">Social Media Auftritte und E-Mail-Kommunikation</li>
          <li class="adsimple-112110023">mobile Apps für Smartphones und andere Geräte</li>
      </ul>
      <p>
          <strong class="adsimple-112110023">Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in
          denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten
          wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert
          informieren.</p>
      <h2 id="rechtsgrundlagen" class="adsimple-112110023">Rechtsgrundlagen</h2>
      <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen
          und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen,
          personenbezogene Daten zu verarbeiten.<br>
          Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES
          RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf
          EUR-Lex, dem Zugang zum EU-Recht, unter <a class="adsimple-112110023" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679</a>
          nachlesen.</p>
      <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
      <ol>
          <li class="adsimple-112110023">
              <strong class="adsimple-112110023">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns
              Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung
              Ihrer eingegebenen Daten eines Kontaktformulars.</li>
          <li class="adsimple-112110023">
              <strong class="adsimple-112110023">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder
              vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel
              einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.</li>
          <li class="adsimple-112110023">
              <strong class="adsimple-112110023">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn
              wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir
              gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel
              personenbezogene Daten.</li>
          <li class="adsimple-112110023">
              <strong class="adsimple-112110023">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im
              Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung
              personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und
              wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
      </ol>
      <p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt
          sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche
          Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
      <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
      <ul class="adsimple-112110023">
          <li class="adsimple-112110023">In <strong class="adsimple-112110023">Österreich</strong> ist dies das
              Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong class="adsimple-112110023">Datenschutzgesetz</strong>), kurz <strong class="adsimple-112110023">DSG</strong>.</li>
          <li class="adsimple-112110023">In <strong class="adsimple-112110023">Deutschland</strong> gilt das <strong class="adsimple-112110023">Bundesdatenschutzgesetz</strong>, kurz<strong class="adsimple-112110023">
                  BDSG</strong>.</li>
      </ul>
      <p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden
          Abschnitten darüber.</p>
      <h2 id="kontaktdaten-verantwortliche" class="adsimple-112110023">Kontaktdaten des Verantwortlichen</h2>
      <p>Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, finden Sie nachfolgend
          die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br>
          <span class="adsimple-112110023" style="font-weight: 400">Roland Hackl<br>
              Rohrbacherstraße 17, 4154 Kollerschlag</span>
      </p>
      <p>E-Mail: <a href="mailto:roland@sidanet.at">roland@sidanet.at</a>
          <br>
          Telefon: <a href="tel:+43 660 6036399">+43 660 6036399</a>
          <br>
          Impressum: <a href="https://sidanet.at/impressum.php">https://sidanet.at/impressum.php</a>
      </p>
      <h2 id="speicherdauer" class="adsimple-112110023">Speicherdauer</h2>
      <p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen
          und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir
          personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen
          Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu
          speichern, zum Beispiel zu Zwecken der Buchführung.</p>
      <p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die
          Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
      <p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere
          Informationen dazu haben.</p>
      <h2 id="rechte-dsgvo" class="adsimple-112110023">Rechte laut Datenschutz-Grundverordnung</h2>
      <p>Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen zustehen, damit es zu einer
          fairen und transparenten Verarbeitung von Daten kommt:</p>
      <ul class="adsimple-112110023">
          <li class="adsimple-112110023">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von
              Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die
              folgenden Informationen zu erfahren:
              <ul class="adsimple-112110023">
                  <li class="adsimple-112110023">zu welchem Zweck wir die Verarbeitung durchführen;</li>
                  <li class="adsimple-112110023">die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
                  <li class="adsimple-112110023">wer diese Daten erhält und wenn die Daten an Drittländer übermittelt
                      werden, wie die Sicherheit garantiert werden kann;</li>
                  <li class="adsimple-112110023">wie lange die Daten gespeichert werden;</li>
                  <li class="adsimple-112110023">das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der
                      Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
                  <li class="adsimple-112110023">dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu
                      diesen Behörden finden Sie weiter unten);</li>
                  <li class="adsimple-112110023">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
                  <li class="adsimple-112110023">ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet
                      werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
              </ul>
          </li>
          <li class="adsimple-112110023">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was
              bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
          <li class="adsimple-112110023">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
              Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
          <li class="adsimple-112110023">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was
              bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
          <li class="adsimple-112110023">Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet,
              dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
          <li class="adsimple-112110023">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung
              eine Änderung der Verarbeitung mit sich bringt.
              <ul class="adsimple-112110023">
                  <li class="adsimple-112110023">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e
                      (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes
                      Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so
                      rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
                  <li class="adsimple-112110023">Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie
                      jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht
                      mehr für Direktmarketing verwenden.</li>
                  <li class="adsimple-112110023">Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit
                      gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für
                      Profiling verwenden.</li>
              </ul>
          </li>
          <li class="adsimple-112110023">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer
              ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung
              unterworfen zu werden.</li>
          <li class="adsimple-112110023">Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie können
              sich jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die Datenverarbeitung
              von personenbezogenen Daten gegen die DSGVO verstößt.</li>
      </ul>
      <p>
          <strong class="adsimple-112110023">Kurz gesagt:</strong> Sie haben Rechte – zögern Sie nicht, die oben
          gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
      <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
          datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der
          Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a class="adsimple-112110023" href="https://www.dsb.gv.at/?tid=112110023" target="_blank" rel="noopener">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen
          Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a class="adsimple-112110023" href="https://www.bfdi.bund.de/DE/Home/home_node.html" target="_blank" rel="noopener">Bundesbeauftragte für
              den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende
          lokale Datenschutzbehörde zuständig:</p>
      <h2 id="oesterreich-datenschutzbehoerde" class="adsimple-112110023">Österreich Datenschutzbehörde</h2>
      <p>
          <strong class="adsimple-112110023">Leiterin: </strong>Mag. Dr. Andrea Jelinek<strong class="adsimple-112110023">
              <br>
              Adresse: </strong>Barichgasse 40-42, 1030 Wien<strong class="adsimple-112110023">
              <br>
              Telefonnr.: </strong>+43 1 52&nbsp;152-0<strong class="adsimple-112110023">
              <br>
              E-Mail-Adresse: </strong>
          <a class="adsimple-112110023" href="mailto:dsb@dsb.gv.at" target="_blank" rel="noopener">dsb@dsb.gv.at</a>
          <strong class="adsimple-112110023">
              <br>
              Website: </strong>
          <a class="adsimple-112110023" href="https://www.dsb.gv.at/" target="_blank" rel="noopener">https://www.dsb.gv.at/</a>
      </p>
      <h2 id="sicherheit-datenverarbeitung" class="adsimple-112110023">Sicherheit der Datenverarbeitung</h2>
      <p>Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen umgesetzt.
          Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im
          Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche Informationen
          schließen können.</p>
      <p>Art. 25 DSGVO spricht hier von “Datenschutz durch Technikgestaltung und durch datenschutzfreundliche
          Voreinstellungen” und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware (z.
          B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir,
          falls erforderlich, noch auf konkrete Maßnahmen ein.</p>
      <h2 id="tls-verschluesselung-https" class="adsimple-112110023">TLS-Verschlüsselung mit https</h2>
      <p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext
          Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet
          zu übertragen.<br>
          Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist
          – niemand kann “mithören”.</p>
      <p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen den Datenschutz durch
          Technikgestaltung (<a class="adsimple-112110023" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=112110023" target="_blank" rel="noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer
          Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet, können wir den Schutz
          vertraulicher Daten sicherstellen.<br>
          Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg" width="17" height="18">
          links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas
          https (anstatt http) als Teil unserer Internetadresse.<br>
          Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach “Hypertext
          Transfer Protocol Secure wiki” um gute Links zu weiterführenden Informationen zu erhalten.</p>
      <h2 id="kommunikation" class="adsimple-112110023">Kommunikation</h2>
      <table border="1" cellpadding="15">
          <tbody>
              <tr>
                  <td>
                      <strong class="adsimple-112110023">Kommunikation Zusammenfassung</strong>
                      <br>
                      👥 Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br>
                      📓 Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten.
                      Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br>
                      🤝 Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br>
                      📅 Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br>
                      ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b
                      DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
              </tr>
          </tbody>
      </table>
      <p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur
          Verarbeitung personenbezogener Daten kommen.</p>
      <p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs
          verarbeitet. Die Daten während eben solange gespeichert bzw. solange es das Gesetz vorschreibt.</p>
      <h3 class="adsimple-112110023">Betroffene Personen</h3>
      <p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den
          Kontakt zu uns suchen.</p>
      <h3 class="adsimple-112110023">Telefon</h3>
      <p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten
          Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im
          Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald
          der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
      <h3 class="adsimple-112110023">E-Mail</h3>
      <p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer,
          Laptop, Smartphone,…) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten
          werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
      <h3 class="adsimple-112110023">Online Formulare</h3>
      <p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und
          gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der
          Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
      <h3 class="adsimple-112110023">Rechtsgrundlagen</h3>
      <p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
      <ul class="adsimple-112110023">
          <li class="adsimple-112110023">Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre
              Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;</li>
          <li class="adsimple-112110023">Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die
              Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir
              müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;
          </li>
          <li class="adsimple-112110023">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen
              und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische
              Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die
              Kommunikation effizient betreiben zu können.</li>
      </ul>
      <h2 id="cookies" class="adsimple-112110023">Cookies</h2>
      <table border="1" cellpadding="15">
          <tbody>
              <tr>
                  <td>
                      <strong class="adsimple-112110023">Cookies Zusammenfassung</strong>
                      <br>
                      👥 Betroffene: Besucher der Website<br>
                      🤝 Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim
                      Hersteller der Software, der das Cookie setzt.<br>
                      📓 Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden Sie
                      weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br>
                      📅 Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren
                      variieren<br>
                      ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f
                      DSGVO (Berechtigte Interessen)</td>
              </tr>
          </tbody>
      </table>
      <h3 class="adsimple-112110023">Was sind Cookies?</h3>
      <p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br>
          Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
          Datenschutzerklärung besser verstehen.</p>
      <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise
          Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine
          Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
      <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites verwenden
          Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche
          gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese
          Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht.
          Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder
          mehrere Attribute angegeben werden.</p>
      <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen.
          Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite
          zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt
          sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle
          Cookies in einer einzigen Datei gespeichert.</p>
      <p>Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem Webserver.
          Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der Browser erneut
          verwendet, sobald eine andere Seite angefordert wird.</p>
      <p>
          <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg" alt="HTTP Cookie Interaktion zwischen Browser und Webserver" width="100%">
      </p>
      <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
          unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt.
          Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines
          Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und
          enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs
          zugreifen.</p>
      <p>So können zum Beispiel Cookie-Daten aussehen:</p>
      <p>
          <strong class="adsimple-112110023">Name:</strong> _ga<br>
          <strong class="adsimple-112110023">Wert:</strong>&nbsp;GA1.2.1326744211.152112110023-9<br>
          <strong class="adsimple-112110023">Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br>
          <strong class="adsimple-112110023">Ablaufdatum:</strong>&nbsp;nach 2 Jahren</p>
      <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
      <ul class="adsimple-112110023">
          <li class="adsimple-112110023">Mindestens 4096 Bytes pro Cookie</li>
          <li class="adsimple-112110023">Mindestens 50 Cookies pro Domain</li>
          <li class="adsimple-112110023">Mindestens 3000 Cookies insgesamt</li>
      </ul>
      <h3 class="adsimple-112110023">Welche Arten von Cookies gibt es?</h3>
      <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den
          folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen
          Arten von HTTP-Cookies eingehen.</p>
      <p>Man kann 4 Arten von Cookies unterscheiden:</p>
      <p>
          <strong class="adsimple-112110023">Unerlässliche Cookies<br>
          </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht
          es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und
          später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein
          Browserfenster schließt.</p>
      <p>
          <strong class="adsimple-112110023">Zweckmäßige Cookies<br>
          </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt.
          Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern
          gemessen.</p>
      <p>
          <strong class="adsimple-112110023">Zielorientierte Cookies<br>
          </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene
          Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
      <p>
          <strong class="adsimple-112110023">Werbe-Cookies<br>
          </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste
          Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
      <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie zulassen
          möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
      <p>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a class="adsimple-112110023" href="https://datatracker.ietf.org/doc/html/rfc6265">https://datatracker.ietf.org/doc/html/rfc6265</a>,&nbsp;dem
          Request for Comments der Internet Engineering Task Force (IETF) namens “HTTP State Management
          Mechanism”.</p>
      <h3 class="adsimple-112110023">Zweck der Verarbeitung über Cookies</h3>
      <p>Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim
          Hersteller der Software, die das Cookie setzt.</p>
      <h3 class="adsimple-112110023">Welche Daten werden verarbeitet?</h3>
      <p>Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert werden,
          kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden Datenschutzerklärung über die
          verarbeiteten bzw. gespeicherten Daten informieren.</p>
      <h3 class="adsimple-112110023">Speicherdauer von Cookies</h3>
      <p>Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden nach
          weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert bleiben.</p>
      <p>Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies
          jederzeit manuell löschen (siehe auch unten “Widerspruchsrecht”). Ferner werden Cookies, die auf
          einer Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit der
          Speicherung bis dahin unberührt bleibt.</p>
      <h3 class="adsimple-112110023">Widerspruchsrecht – wie kann ich Cookies löschen?</h3>
      <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher
          Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur
          teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies
          zulassen.</p>
      <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen
          ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
      <p>
          <a class="adsimple-112110023" href="https://support.google.com/chrome/answer/95647?tid=112110023" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
      </p>
      <p>
          <a class="adsimple-112110023" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=112110023" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
      </p>
      <p>
          <a class="adsimple-112110023" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=112110023" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
              Ihrem Computer abgelegt haben</a>
      </p>
      <p>
          <a class="adsimple-112110023" href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=112110023">Internet
              Explorer: Löschen und Verwalten von Cookies</a>
      </p>
      <p>
          <a class="adsimple-112110023" href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=112110023">Microsoft
              Edge: Löschen und Verwalten von Cookies</a>
      </p>
      <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
          informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie
          das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die
          Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome”
          im Falle eines Chrome Browsers.</p>
      <h3 class="adsimple-112110023">Rechtsgrundlage</h3>
      <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies
          eine <strong class="adsimple-112110023">Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen
          verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese
          Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des
          Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht
          umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes
          (TMG).</p>
      <p>Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt, bestehen <strong class="adsimple-112110023">berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den
          meisten Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme
          Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.</p>
      <p>Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer Einwilligung.
          Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
      <p>In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern eingesetzte
          Software Cookies verwendet.</p>
      <h2 id="webdesign-einleitung" class="adsimple-112110023">Webdesign Einleitung</h2>
      <table border="1" cellpadding="15">
          <tbody>
              <tr>
                  <td>
                      <strong class="adsimple-112110023">Webdesign Datenschutzerklärung Zusammenfassung</strong>
                      <br>
                      👥 Betroffene: Besucher der Website<br>
                      🤝 Zweck: Verbesserung der Nutzererfahrung<br>
                      📓 Verarbeitete Daten: Welche Daten verarbeitet werden, hängt stark von den verwendeten
                      Diensten ab. Meist handelt es sich etwa um IP-Adresse, technische Daten, Spracheinstellungen,&nbsp;
                      Browserversion, Bildschirmauflösung und Name des Browsers. Mehr Details dazu finden Sie bei den
                      jeweils eingesetzten Webdesign-Tools.<br>
                      📅 Speicherdauer: abhängig von den eingesetzten Tools<br>
                      ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                      DSGVO (Berechtigte Interessen)</td>
              </tr>
          </tbody>
      </table>
      <h3 class="adsimple-112110023">Was ist Webdesign?</h3>
      <p>Wir verwenden auf unserer Website verschiedene Tools, die unserem Webdesign dienen. Bei Webdesign geht es nicht,
          wie oft angenommen, nur darum, dass unsere Website hübsch aussieht, sondern auch um Funktionalität und Leistung.
          Aber natürlich ist die passende Optik einer Website auch eines der großen Ziele professionellen Webdesigns.
          Webdesign ist ein Teilbereich des Mediendesigns und beschäftigt sich sowohl mit der visuellen als auch der
          strukturellen und funktionalen Gestaltung einer Website. Ziel ist es mit Hilfe von Webdesign Ihre Erfahrung auf
          unserer Website zu verbessern. Im Webdesign-Jargon spricht man in diesem Zusammenhang von User-Experience (UX)
          und Usability. Unter User Experience versteht man alle Eindrücke und Erlebnisse, die der Websitebesucher auf
          einer Website erfährt. Ein Unterpunkt der User Experience ist die Usability. Dabei geht es um die
          Nutzerfreundlichkeit einer Website. Wert gelegt wird hier vor allem darauf, dass Inhalte, Unterseiten oder
          Produkte klar strukturiert sind und Sie leicht und schnell finden, wonach Sie suchen. Um Ihnen die bestmögliche
          Erfahrung auf unserer Website zu bieten, verwenden wir auch sogenannte Webdesign-Tools von Drittanbietern. Unter
          die Kategorie „Webdesign“ fallen in dieser Datenschutzerklärung also alle Dienste, die unsere Website
          gestalterisch verbessern. Das können beispielsweise Schriftarten, diverse Plugins oder andere eingebundene
          Webdesign-Funktionen sein.</p>
      <h3 class="adsimple-112110023">Warum verwenden wir Webdesign-Tools?</h3>
      <p>Wie Sie Informationen auf einer Website aufnehmen, hängt sehr stark von der Struktur, der Funktionalität und der
          visuellen Wahrnehmung der Website ab. Daher wurde auch für uns ein gutes und professionelles Webdesign immer
          wichtiger. Wir arbeiten ständig an der Verbesserung unserer Website und sehen dies auch als erweiterte
          Dienstleistung für Sie als Websitebesucher. Weiters hat eine schöne und funktionierende Website auch
          wirtschaftliche Vorteile für uns. Schließlich werden Sie uns nur besuchen und unsere Angebote in Anspruch
          nehmen, wenn Sie sich rundum wohl fühlen.</p>
      <h3 class="adsimple-112110023">Welche Daten werden durch Webdesign-Tools gespeichert?</h3>
      <p>Wenn Sie unsere Website besuchen, können Webdesign-Elemente in unseren Seiten eingebunden sein, die auch Daten
          verarbeiten können. Um welche Daten es sich genau handelt, hängt natürlich stark von den verwendeten Tools ab.
          Weiter unter sehen Sie genau, welche Tools wir für unsere Website verwenden. Wir empfehlen Ihnen für nähere
          Informationen über die Datenverarbeitung auch die jeweilige Datenschutzerklärung der verwendeten Tools
          durchzulesen. Meistens erfahren Sie dort, welche Daten verarbeitet werden, ob Cookies eingesetzt werden und wie
          lange die Daten aufbewahrt werden. Durch Schriftarten wie etwa Google Fonts werden beispielsweise auch
          Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und
          Name des Browsers automatisch an die Google-Server übertragen.</p>
      <h3 class="adsimple-112110023">Dauer der Datenverarbeitung</h3>
      <p>Wie lange Daten verarbeitet werden, ist sehr individuell und hängt von den eingesetzten Webdesign-Elementen ab.
          Wenn Cookies beispielsweise zum Einsatz kommen, kann die Aufbewahrungsdauer nur eine Minute, aber auch ein paar
          Jahre dauern. Machen Sie sich diesbezüglich bitte schlau. Dazu empfehlen wir Ihnen einerseits unseren
          allgemeinen Textabschnitt über Cookies sowie die Datenschutzerklärungen der eingesetzten Tools. Dort erfahren
          Sie in der Regel, welche Cookies genau eingesetzt werden, und welche Informationen darin gespeichert werden.
          Google-Font-Dateien werden zum Beispiel ein Jahr gespeichert. Damit soll die Ladezeit einer Website verbessert
          werden. Grundsätzlich werden Daten immer nur so lange aufbewahrt, wie es für die Bereitstellung des Dienstes
          nötig ist. Bei gesetzlichen Vorschreibungen können Daten auch länger gespeichert werden.</p>
      <h3 class="adsimple-112110023">Widerspruchsrecht</h3>
      <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw.
          Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere
          Opt-Out-Funktionen. Sie können auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die
          Cookies verwalten, deaktivieren oder löschen. Unter Webdesign-Elementen (meistens bei Schriftarten) gibt es
          allerdings auch Daten, die nicht ganz so einfach gelöscht werden können. Das ist dann der Fall, wenn Daten
          direkt bei einem Seitenaufruf automatisch erhoben und an einen Drittanbieter (wie z. B. Google) übermittelt
          werden. Wenden Sie sich dann bitte an den Support des entsprechenden Anbieters. Im Fall von Google erreichen Sie
          den Support unter <a class="adsimple-112110023" href="https://support.google.com/?hl=de">https://support.google.com/?hl=de</a>.</p>
      <h3 class="adsimple-112110023">Rechtsgrundlage</h3>
      <p>Wenn Sie eingewilligt haben, dass Webdesign-Tools eingesetzt werden dürfen, ist die Rechtsgrundlage der
          entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO
          (Einwilligung) die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch
          Webdesign-Tools vorkommen kann, dar. Von unserer Seite besteht zudem ein berechtigtes Interesse, das Webdesign
          auf unserer Website zu verbessern. Schließlich können wir Ihnen nur dann ein schönes und professionelles
          Webangebot liefern. Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
          Interessen). Wir setzen Webdesign-Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Das
          wollen wir hier auf jeden Fall nochmals betonen.</p>
      <p>Informationen zu speziellen Webdesign-Tools erhalten Sie – sofern vorhanden – in den folgenden
          Abschnitten.</p>
      <h2 id="font-awesome-datenschutzerklaerung" class="adsimple-112110023">Font Awesome Datenschutzerklärung</h2>
      <table border="1" cellpadding="15">
          <tbody>
              <tr>
                  <td>
                      <strong class="adsimple-112110023">Font Awesome Datenschutzerklärung Zusammenfassung</strong>
                      <br>
                      👥 Betroffene: Besucher der Website<br>
                      🤝 Zweck: Optimierung unserer Serviceleistung<br>
                      📓 Verarbeitete Daten: etwa IP-Adresse und und welche Icon-Dateien geladen werden<br>
                      Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br>
                      📅 Speicherdauer: Dateien in identifizierbarer Form werden wenige Wochen gespeichert<br>
                      ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                      DSGVO (Berechtigte Interessen)</td>
              </tr>
          </tbody>
      </table>
      <h3 class="adsimple-112110023">Was ist Font Awesome?</h3>
      <p>Wir verwenden auf unserer Website Font Awesome des amerikanischen Unternehmens Fonticons (307 S. Main St., Suite
          202, Bentonville, AR 72712, USA). Wenn Sie eine unserer Webseite aufrufen, wird die Web-Schriftart Font Awesome
          (im Speziellen Icons) über das Font Awesome Content Delivery Netzwerk (CDN) geladen. So werden die Texte bzw.
          Schriften und Icons auf jedem Endgerät passend angezeigt. In dieser Datenschutzerklärung gehen wir näher auf die
          Datenspeicherung und Datenverarbeitung durch diesen Service ein.</p>
      <p>Icons spielen für Websites eine immer wichtigere Rolle. Font Awesome ist eine Web-Schriftart, die speziell für
          Webdesigner und Webentwickler entwickelt wurde. Mit Font Awesome können etwa Icons mit Hilfe der
          Stylesheet-Sprache CSS nach Belieben skaliert und gefärbt werden. Sie ersetzen so alte Bild-Icons. Font Awesome
          CDN ist der einfachste Weg die Icons oder Schriftarten auf Ihre Website zu laden. Dafür mussten wir nur eine
          kleine Code-Zeile in unsere Website einbinden.</p>
      <h3 class="adsimple-112110023">Warum verwenden wir Font Awesome auf unserer Website?</h3>
      <p>Durch Font Awesome können Inhalte auf unserer Website besser aufbereitet werden. So können Sie sich auf unserer
          Website besser orientieren und die Inhalte leichter erfassen. Mit den Icons kann man sogar manchmal ganze Wörter
          ersetzen und Platz sparen. Da ist besonders praktisch, wenn wir Inhalte speziell für Smartphones optimieren.&nbsp;
          Diese Icons werden statt als Bild als HMTL-Code eingefügt. Dadurch können wir die Icons mit CSS genauso
          bearbeiten, wie wir wollen. Gleichzeitig verbessern wir mit Font Awesome auch unsere Ladegeschwindigkeit, weil
          es sich nur um HTML-Elemente handelt und nicht um Icon-Bilder. All diese Vorteile helfen uns, die Website für
          Sie noch übersichtlicher, frischer und schneller zu machen.</p>
      <h3 class="adsimple-112110023">Welche Daten werden von Font Awesome gespeichert?</h3>
      <p>Zum Laden von Icons und Symbolen wird das Font Awesome Content Delivery Network (CDN) verwendet. CDNs sind
          Netzwerke von Servern, die weltweit verteilt sind und es möglich machen, schnell Dateien aus der Nähe zu laden.
          So werden auch, sobald Sie eine unserer Seiten aufrufen, die entsprechenden Icons von Font Awesome
          bereitgestellt.</p>
      <p>Damit die Web-Schriftarten geladen werden können, muss Ihr Browser eine Verbindung zu den Servern des
          Unternehmens Fonticons, Inc. herstellen. Dabei wird Ihre IP-Adresse erkannt. Font Awesome sammelt auch Daten
          darüber, welche Icon-Dateien wann heruntergeladen werden. Weiters werden auch technische Daten wie etwa Ihre
          Browser-Version, Bildschirmauflösung oder der Zeitpunkt der ausgerufenen Seite übertragen.</p>
      <p>Aus folgenden Gründen werden diese Daten gesammelt und gespeichert:</p>
      <ul class="adsimple-112110023">
          <li class="adsimple-112110023">um Content Delivery Netzwerke zu optimieren</li>
          <li class="adsimple-112110023">um technische Fehler zu erkennen und zu beheben</li>
          <li class="adsimple-112110023">um CDNs vor Missbrauch und Angriffen zu schützen</li>
          <li class="adsimple-112110023">um Gebühren von Font Awesome Pro-Kunden berechnen zu können</li>
          <li class="adsimple-112110023">um die Beliebtheit von Icons zu erfahren</li>
          <li class="adsimple-112110023">um zu wissen, welchen Computer und welche Software Sie verwenden</li>
      </ul>
      <p>Falls Ihr Browser Web-Schriftarten nicht zulässt, wird automatisch eine Standardschrift Ihres PCs verwendet. Nach
          derzeitigem Stand unserer Erkenntnis werden keine Cookies gesetzt. Wir sind mit der Datenschutzabteilung von
          Font Awesome in Kontakt und geben Ihnen Bescheid, sobald wir näheres in Erfahrung bringen.</p>
      <h3 class="adsimple-112110023">Wie lange und wo werden die Daten gespeichert?</h3>
      <p>Font Awesome speichert Daten über die Nutzung des Content Delivery Network auf Servern auch in den Vereinigten
          Staaten von Amerika. Die CDN-Server befinden sich allerdings weltweit und speichern Userdaten, wo Sie sich
          befinden. In identifizierbarer Form werden die Daten in der Regel nur wenige Wochen gespeichert. Aggregierte
          Statistiken über die Nutzung von den CDNs können auch länger gespeichert werden. Personenbezogene Daten sind
          hier nicht enthalten.</p>
      <h3 class="adsimple-112110023">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
      <p>Font Awesome speichert nach aktuellem Stand unseres Wissens keine personenbezogenen Daten über die Content
          Delivery Netzwerke. Wenn Sie nicht wollen, dass Daten über die verwendeten Icons gespeichert werden, können Sie
          leider unsere Website nicht besuchen. Wenn Ihr Browser keine Web-Schriftarten erlaubt, werden auch keine Daten
          übertragen oder gespeichert. In diesem Fall wird einfach die Standard-Schrift Ihres Computers verwendet.</p>
      <h3 class="adsimple-112110023">Rechtsgrundlage</h3>
      <p>Wenn Sie eingewilligt haben, dass Font Awesome eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden
          Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong class="adsimple-112110023"> Art. 6
              Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener
          Daten, wie sie bei der Erfassung durch Font Awesome vorkommen kann, dar.</p>
      <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Font Awesome zu verwenden, um unser Online-Service zu
          optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong class="adsimple-112110023">Art. 6 Abs. 1 lit. f
              DSGVO (Berechtigte Interessen)</strong>. Wir setzen Font Awesome gleichwohl nur ein, soweit Sie eine
          Einwilligung erteilt haben.</p>
      <p>Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für
          den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch Font Awesome. Dies
          kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner können
          gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen. Es kann ferner vorkommen,
          dass diese Daten mit Daten aus möglichen anderen Diensten von Font Awesome, bei denen Sie ein Nutzerkonto haben,
          verknüpft werden.</p>
      <p>Wenn Sie mehr über Font Awesome und deren Umgang mit Daten erfahren wollen, empfehlen wir Ihnen die
          Datenschutzerklärung unter <a class="adsimple-112110023" href="https://fontawesome.com/privacy">https://fontawesome.com/privacy</a> und die Hilfeseite unter <a class="adsimple-112110023" href="https://fontawesome.com/support" target="_blank" rel="follow noopener">https://fontawesome.com/support</a>.</p>
      <h2 id="erklaerung-verwendeter-begriffe" class="adsimple-112110023">Erklärung verwendeter Begriffe</h2>
      <p>Wir sind stets bemüht unsere Datenschutzerklärung so klar und verständlich wie möglich zu verfassen. Besonders
          bei technischen und rechtlichen Themen ist das allerdings nicht immer ganz einfach. Es macht oft Sinn
          juristische Begriffe (wie z. B. personenbezogene Daten) oder bestimmte technische Ausdrücke (wie z. B. Cookies,
          IP-Adresse) zu verwenden. Wir möchte diese aber nicht ohne Erklärung verwenden. Nachfolgend finden Sie nun eine
          alphabetische Liste von wichtigen verwendeten Begriffen, auf die wir in der bisherigen Datenschutzerklärung
          vielleicht noch nicht ausreichend eingegangen sind. Falls diese Begriffe der DSGVO entnommen wurden und es sich
          um Begriffsbestimmungen handelt, werden wir hier auch die DSGVO-Texte anführen und gegebenenfalls noch eigene
          Erläuterungen hinzufügen.</p>
      <h2 id="einwilligung" class="adsimple-112110023">Einwilligung</h2>
      <p>
          <strong class="adsimple-112110023">Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
      </p>
      <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
      <blockquote>
          <p>
              <em>
                  <strong class="adsimple-112110023">„Einwilligung“</strong> der betroffenen Person jede freiwillig für
                  den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form
                  einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person
                  zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten
                  einverstanden ist;</em>
          </p>
      </blockquote>
      <p>
          <strong class="adsimple-112110023">Erläuterung: </strong>In der Regel erfolgt bei Websites eine solche
          Einwilligung über ein Cookie-Consent-Tool. Sie kennen das bestimmt. Immer wenn Sie erstmals eine Website
          besuchen, werden Sie meist über einen Banner gefragt, ob Sie der Datenverarbeitung zustimmen bzw. einwilligen.
          Meist können Sie auch individuelle Einstellungen treffen und so selbst entscheiden, welche Datenverarbeitung Sie
          erlauben und welche nicht. Wenn Sie nicht einwilligen, dürfen auch keine personenbezogene Daten von Ihnen
          verarbeitet werden. Grundsätzlich kann eine Einwilligung natürlich auch schriftlich, also nicht über ein Tool,
          erfolgen.</p>
      <h2 id="personenbezogene-daten" class="adsimple-112110023">Personenbezogene Daten</h2>
      <p>
          <strong class="adsimple-112110023">Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
      </p>
      <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
      <blockquote>
          <p>
              <strong class="adsimple-112110023">
                  <em>„personenbezogene Daten“</em>
              </strong>
              <em> alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im
                  Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die
                  direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
                  Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen,
                  die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen
                  oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann;</em>
          </p>
      </blockquote>
      <p>
          <strong class="adsimple-112110023">Erläuterung:</strong> Personenbezogene Daten sind also all jene Daten, die
          Sie als Person identifizieren können. Das sind in der Regel Daten wie etwa:</p>
      <ul class="adsimple-112110023">
          <li class="adsimple-112110023">Name</li>
          <li class="adsimple-112110023">Adresse</li>
          <li class="adsimple-112110023">E-Mail-Adresse</li>
          <li class="adsimple-112110023">Post-Anschrift</li>
          <li class="adsimple-112110023">Telefonnummer</li>
          <li class="adsimple-112110023">Geburtsdatum</li>
          <li class="adsimple-112110023">Kennnummern wie Sozialversicherungsnummer, Steueridentifikationsnummer,
              Personalausweisnummer oder Matrikelnummer</li>
          <li class="adsimple-112110023">Bankdaten wie Kontonummer, Kreditinformationen, Kontostände uvm.</li>
      </ul>
      <p>Laut Europäischem Gerichtshof (EuGH) zählt auch Ihre <strong class="adsimple-112110023">IP-Adressen zu den
              personenbezogenen Daten</strong>. IT-Experten können anhand Ihrer IP-Adresse zumindest den ungefähren
          Standort Ihres Geräts und in weiterer Folge Sie als Anschlussinhabers feststellen. Daher benötigt auch das
          Speichern einer IP-Adresse eine Rechtsgrundlage im Sinne der DSGVO. Es gibt auch noch sogenannte <strong class="adsimple-112110023">„besondere Kategorien“</strong> der personenbezogenen Daten, die auch besonders
          schützenswert sind. Dazu zählen:</p>
      <ul class="adsimple-112110023">
          <li class="adsimple-112110023">rassische und ethnische Herkunft</li>
          <li class="adsimple-112110023">politische Meinungen</li>
          <li class="adsimple-112110023">religiöse bzw. weltanschauliche Überzeugungen</li>
          <li class="adsimple-112110023">die Gewerkschaftszugehörigkeit</li>
          <li class="adsimple-112110023">genetische Daten wie beispielsweise Daten, die aus Blut- oder Speichelproben
              entnommen werden</li>
          <li class="adsimple-112110023">biometrische Daten (das sind Informationen zu psychischen, körperlichen oder
              verhaltenstypischen Merkmalen, die eine Person identifizieren können).<br>
              Gesundheitsdaten</li>
          <li class="adsimple-112110023">Daten zur sexuellen Orientierung oder zum Sexualleben</li>
      </ul>
      <h2 id="profiling" class="adsimple-112110023">Profiling</h2>
      <p>
          <strong class="adsimple-112110023">Begriffsbestimmung nach Artikel 4 der DSGVO</strong>
      </p>
      <p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
      <blockquote>
          <p>
              <em>
                  <strong class="adsimple-112110023">„Profiling“</strong> jede Art der automatisierten Verarbeitung
                  personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um
                  bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere
                  um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben,
                  Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu
                  analysieren oder vorherzusagen;</em>
          </p>
      </blockquote>
      <p>
          <strong class="adsimple-112110023">Erläuterung:</strong> Beim Profiling werden verschiedene Informationen über
          eine Person zusammengetragen, um daraus mehr über diese Person zu erfahren. Im Webbereich wird Profiling häufig
          für Werbezwecke oder auch für Bonitätsprüfungen angewandt. Web- bzw. Werbeanalyseprogramme sammeln zum Beispiel
          Daten über Ihre Verhalten und Ihre Interessen auf einer Website. Daraus ergibt sich ein spezielles Userprofil,
          mit dessen Hilfe Werbung gezielt an eine Zielgruppe ausgespielt werden kann.</p>
      <p>&nbsp;</p>
      <p>Alle Texte sind urheberrechtlich geschützt.</p>
      <p style="margin-top:15px">Quelle: Erstellt mit dem <a href="https://www.adsimple.at/datenschutz-generator/" title="Datenschutz Generator von AdSimple für Österreich">Datenschutz Generator</a> von AdSimple</p>

        </div>
    </main>
</template>


<script>
export default {
  computed: {
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.closeMenu();
  },
  methods: {
    closeMenu() {
      var toggler = document.getElementById('navbarCollapse');
      try {
        toggler.classList.remove("hide");
      } catch (error) {
        toggler.classList.remove("show");
      } 

    },
  }
};
</script>