<template>

    <tr>
        <td>{{ lastapp }}  ({{ disposition }})</td>
        <td>{{ src }} </td>
        <td> {{ dst }} </td>
        <td>{{ duration }} Sekunden </td>
        <td>{{ calldate }}</td>
    </tr>
  
    
  
    </template>
    <script>
    export default {
      props: ['id', 'calldate', 'src', 'dst', 'dcontext','destchannel', 'lastapp', 'lastdata', 'duration', 'billsec', 'disposition', 'uniqueid'],
      // computed: {
      //   fullName() {
      //     return this.firstName + ' ' + this.lastName;
      //   },
      //   coachContactLink() {
      //     return this.$route.path + '/' + this.id + '/contact'; // /coaches/c1/contact
      //   },
      //   coachDetailsLink() {
      //     return this.$route.path + '/' + this.id; // /coaches/c1
      //   },
      // },
      data() {
        return {
            // editVorname: '',
            // editNachname: '',
            // editEmail: '',
            // editStrasse: '',
            // editHausnummer: '',
            // editPlz: '',
            // editOrt: '',
            // editLand: '',
            // doProfileEdit: false,
        };
      },
      methods: {
        // editProfile() {
        //     this.doProfileEdit = true;
        //     this.editVorname = this.vorname,
        //     this.editNachname = this.nachname,
        //     this.editEmail = this.email,
        //     this.editStrasse = this.strasse,
        //     this.editHausnummer = this.hausnummer,
        //     this.editPlz = this.plz,
        //     this.editOrt = this.ort,
        //     this.editLand = this.land,
        //     console.log('isAuth: ' + this.$store.getters.isAuthenticated);
        //   },
        // closeEditProfilej() {
        //     this.doProfileEdit = false;
        //     console.log('isAuth: ' + this.$store.getters.isAuthenticated);
        //   }
      }
    }
    </script>
  