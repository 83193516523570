<template>
  <main>




    <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary">
      <div class="col-md-6 p-lg-5 mx-auto my-5">
        <h1 class="display-3 fw-light">Dokumentation</h1>
        <h3 class="fw-normal text-muted mb-3">Message Caller</h3>
        <hr>
      </div>
      <div class="product-device shadow-sm d-none d-md-block"></div>
      <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
    </div>






    <br>
    <br>

    <div>



      <!-- wp:heading {"level":3} -->
      <h3>Inhaltsverzeichnis</h3>
      <!-- /wp:heading -->

      <!-- wp:list -->
      <ul>
        <li>Grundlegende Funktionsweise</li>
        <li>Verwendungsbeispiele<ul>
            <li><strong>Alarmanlage</strong></li>
            <li><strong>Brand- oder Wassermelder</strong></li>
            <li><strong>Wartungsintervalle</strong></li>
          </ul>
        </li>
        <li>Hilfreiche Links</li>
        <li>FAQs</li>
      </ul>
      <!-- /wp:list -->

      <!-- wp:spacer -->
      <div style="height:100px" aria-hidden="true" class="wp-block-spacer"></div>
      <!-- /wp:spacer -->

      <!-- wp:heading {"level":3} -->
      <h3>Grundlegende Funktionsweise</h3>
      <!-- /wp:heading -->

      <!-- wp:paragraph -->
      <p>Die Grundlegende Idee ist über HTTPS einen vom Loxone Miniserver aus einen Request abzuschicken und dieser ruft
        bei erfolgreich hinterlegter Rufnummer und abgeschlossenem Abo genau diese Nummer an. Dabei sollte man beachten,
        dass ein Serverseitiges Limit pro Abo von 2 Minuten gesetzt ist.</p>
      <!-- /wp:paragraph -->

      <!-- wp:paragraph -->
      <p>Da man in der Loxonekonfig relativ leicht so einen Request absetzen kann und auch für verschiedenste Alarme
        oder Erinnerungsevents andere Benachrichtigungstexte einstellen kann, kommen diese einfach in den HTTPS aufruf
        hinein. </p>
      <!-- /wp:paragraph -->

      <!-- wp:paragraph -->
      <p>Bei Alarmen oder Erinnerungen benötigt man für gewöhnlich eine Bestätigung oder ein Reset. Diese sollte auch
        per Telefontaste während des Anrufs möglich sein, daher wird diese Info nach dem Anruf lokal am Miniserver
        abgelegt und von der Konfiguration im 10 Sekunden Takt abgefragt um ein entsprechendes Reset an die Bausteine zu
        übermitteln. Weiter unten in der Doku werden solche Beispiele zu finden sein.</p>
      <!-- /wp:paragraph -->

      <!-- wp:paragraph -->
      <p>Eine solche Bestätigung kann aber auch wesentlich unkomplizierter konfiguriert werden, indem man diese über
        einen Button in der Loxone App realisiert. Auch ein solches Beispiel wird weiter unten zu finden sein.</p>
      <!-- /wp:paragraph -->

      <!-- wp:spacer -->
      <div style="height:100px" aria-hidden="true" class="wp-block-spacer"></div>
      <!-- /wp:spacer -->

      <!-- wp:heading {"level":3} -->
      <h3>Verwendungsbeispiele</h3>
      <!-- /wp:heading -->

      <!-- wp:spacer {"height":64} -->
      <div style="height:64px" aria-hidden="true" class="wp-block-spacer"></div>
      <!-- /wp:spacer -->

      <!-- wp:heading {"level":4} -->
      <h4>Alarmanlage</h4>
      <!-- /wp:heading -->

      <!-- wp:separator {"className":"is-style-wide"} -->
      <hr class="wp-block-separator is-style-wide" />
      <!-- /wp:separator -->

      <!-- wp:columns -->
      <div class="wp-block-columns"><!-- wp:column -->
        <div class="wp-block-column"><!-- wp:paragraph -->
          <p>Beim vorinstallierten Baustein Alarmanlage von Loxone, möchten wir den Ausgang Q1 ( Name ) als Anlass für
            einen Anruf verwenden.</p>
          <!-- /wp:paragraph -->

          <!-- wp:paragraph -->
          <p>Um den Alarm wieder abzustellen, verwenden wir den Eingang R ( Reset ), welcher bei den Meisten Bausteinen
            gleichnamig ist.</p>
          <!-- /wp:paragraph -->
        </div>
        <!-- /wp:column -->

        <!-- wp:column -->
        <div class="wp-block-column"><!-- wp:paragraph -->
          <p></p>
          <!-- /wp:paragraph -->
        </div>
        <!-- /wp:column -->

        <!-- wp:paragraph -->
        <p>Außerdem verwenden wir für die Konfiguration noch diese Bausteine:</p>
        <!-- /wp:paragraph -->

        <!-- wp:list -->
        <ul>
          <li>Impulsgeber mit 120 Sekunden Aus und 1 Sekunde Ein</li>
          <li>UND Verknüpfung</li>
          <li>ODER Verknüpfung</li>
          <li>"Impuls bei" Baustein (Wertet den Tastendruck am Telefon aus)</li>
          <li>3 Virtuelle VI&amp;VOs (Template Download im Kundenportal)</li>
          <li>Taster für Reset (optional)</li>
        </ul>
        <!-- /wp:list -->

        <!-- wp:ugb/accordion {"uniqueClass":"ugb-b10a0f2"} -->
        <div
          class="wp-block-ugb-accordion ugb-accordion ugb-b10a0f2 ugb-accordion--v2 ugb-accordion--design-basic ugb-main-block"
          aria-expanded="false" id="">
          <div class="ugb-inner-block">
            <div class="ugb-block-content">
              <div class="ugb-accordion__item">
                <div class="ugb-accordion__heading" role="button" tabindex="0">
                  <h4 class="ugb-accordion__title" role="heading" aria-level="3">Loxone Konfiguration</h4><svg
                    viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="ugb-accordion__arrow" width="20"
                    height="20">
                    <path d="M16.7 3.3L10 10 3.3 3.4 0 6.7l10 10v-.1l10-9.9z"></path>
                  </svg>
                </div>
                <div class="ugb-accordion__content" role="region">
                  <div class="ugb-accordion__content-inner"><!-- wp:ugb/container {"uniqueClass":"ugb-88c8069"} -->
                    <div
                      class="wp-block-ugb-container ugb-container ugb-88c8069 ugb-container--v2 ugb-container--design-basic ugb-container--width-small ugb-main-block"
                      id="">
                      <div class="ugb-inner-block">
                        <div class="ugb-block-content">
                          <div class="ugb-container__wrapper ugb-88c8069-wrapper">
                            <div class="ugb-container__side">
                              <div class="ugb-container__content-wrapper ugb-88c8069-content-wrapper">
                                <!-- wp:image {"id":1589,"width":1024,"height":713,"sizeSlug":"large"} -->
                                <figure class="wp-block-image size-large is-resized"><img
                                    src="../../pics/Loxone-Konfiguration-Alarmanlage-1024x713.png" alt=""
                                    class="wp-image-1589" width="100%" height="100%" /></figure>
                                <!-- /wp:image -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /wp:ugb/container -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /wp:ugb/accordion -->

        <!-- wp:paragraph -->
        <p></p>
        <!-- /wp:paragraph -->

        <!-- wp:spacer -->
        <div style="height:100px" aria-hidden="true" class="wp-block-spacer"></div>
        <!-- /wp:spacer -->

        <!-- wp:heading {"level":4} -->
        <h4>Brand-, Wasser oder Temperaturmelder</h4>
        <!-- /wp:heading -->

        <!-- wp:separator {"className":"is-style-wide"} -->
        <hr class="wp-block-separator is-style-wide" />
        <!-- /wp:separator -->

        <!-- wp:columns -->
        <div class="wp-block-columns"><!-- wp:column -->
          <div class="wp-block-column"><!-- wp:paragraph -->
            <p>Es gibt den Baustein Brand und Wassermeldezentrale direkt in der Loxonekonfig, welchen wir verwenden um
              Externe Rauchmelder, Wassermelder, Brandmelder und Temperatursensoren zu verwenden.</p>
            <!-- /wp:paragraph -->

            <!-- wp:paragraph -->
            <p>Um den Alarm wieder abzustellen, müssen wir über die Tastenrückmeldung am Telefon oder über einen Taster
              in der Loxone APP den Baustein wieder Resetten/Quittieren über den Eingang C</p>
            <!-- /wp:paragraph -->
          </div>
          <!-- /wp:column -->


          <!-- wp:ugb/accordion {"uniqueClass":"ugb-81b00c1"} -->
          <div
            class="wp-block-ugb-accordion ugb-accordion ugb-81b00c1 ugb-accordion--v2 ugb-accordion--design-basic ugb-main-block"
            aria-expanded="false" id="">
            <div class="ugb-inner-block">
              <div class="ugb-block-content">
                <div class="ugb-accordion__item">
                  <div class="ugb-accordion__heading" role="button" tabindex="0">
                    <h4 class="ugb-accordion__title" role="heading" aria-level="3">Loxone Konfiguration</h4><svg
                      viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="ugb-accordion__arrow" width="20"
                      height="20">
                      <path d="M16.7 3.3L10 10 3.3 3.4 0 6.7l10 10v-.1l10-9.9z"></path>
                    </svg>
                  </div>
                  <div class="ugb-accordion__content" role="region">
                    <div class="ugb-accordion__content-inner"><!-- wp:ugb/container {"uniqueClass":"ugb-91dfcd3"} -->
                      <div
                        class="wp-block-ugb-container ugb-container ugb-91dfcd3 ugb-container--v2 ugb-container--design-basic ugb-container--width-small ugb-main-block"
                        id="">
                        <div class="ugb-inner-block">
                          <div class="ugb-block-content">
                            <div class="ugb-container__wrapper ugb-91dfcd3-wrapper">
                              <div class="ugb-container__side">
                                <div class="ugb-container__content-wrapper ugb-91dfcd3-content-wrapper">
                                  <!-- wp:image {"id":1587,"width":1024,"height":685,"sizeSlug":"large"} -->
                                  <figure class="wp-block-image size-large is-resized"><img
                                      src="../../pics/Loxone-Konfiguration-Rauch-Wassermelder-1024x685.png" alt=""
                                      class="wp-image-1587" width="100%" height="100%" /></figure>
                                  <!-- /wp:image -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- /wp:ugb/container -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /wp:ugb/accordion -->

          <!-- wp:spacer -->
          <div style="height:100px" aria-hidden="true" class="wp-block-spacer"></div>
          <!-- /wp:spacer -->

          <!-- wp:heading {"level":4} -->
          <h4>Wartungsintervalle</h4>
          <!-- /wp:heading -->

          <!-- wp:separator {"className":"is-style-wide"} -->
          <hr class="wp-block-separator is-style-wide" />
          <!-- /wp:separator -->

          <!-- wp:columns -->
          <div class="wp-block-columns"><!-- wp:column -->
            <div class="wp-block-column"><!-- wp:paragraph -->
              <p>Um Wartungsintervalle mit Erinnerungsanrufe zu realisieren, kann man den Standard Baustein
                Betriebszeitzähler verwenden.</p>
              <!-- /wp:paragraph -->

              <!-- wp:paragraph -->
              <p>Außerdem verwenden wir für die Konfiguration noch diese Bausteine:</p>
              <!-- /wp:paragraph -->

              <!-- wp:list -->
              <ul>
                <li>Impulsgeber mit 120 Sekunden Aus und 1 Sekunde Ein</li>
                <li>UND Verknüpfung</li>
                <li>Konstante mit Wert 1</li>
                <li>"Impuls bei" Baustein (Wertet den Tastendruck am Telefon aus)</li>
                <li>Virtuelle VI&amp;VO (Template Download im Kundenportal)</li>
              </ul>
              <!-- /wp:list -->
            </div>
            <!-- /wp:column -->


            <!-- wp:ugb/accordion {"uniqueClass":"ugb-7ea47c1"} -->
            <div
              class="wp-block-ugb-accordion ugb-accordion ugb-7ea47c1 ugb-accordion--v2 ugb-accordion--design-basic ugb-main-block"
              aria-expanded="false" id="">
              <div class="ugb-inner-block">
                <div class="ugb-block-content">
                  <div class="ugb-accordion__item">
                    <div class="ugb-accordion__heading" role="button" tabindex="0">
                      <h4 class="ugb-accordion__title" role="heading" aria-level="3">Loxone Konfiguration</h4><svg
                        viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="ugb-accordion__arrow" width="20"
                        height="20">
                        <path d="M16.7 3.3L10 10 3.3 3.4 0 6.7l10 10v-.1l10-9.9z"></path>
                      </svg>
                    </div>
                    <div class="ugb-accordion__content" role="region">
                      <div class="ugb-accordion__content-inner"><!-- wp:ugb/container {"uniqueClass":"ugb-3c999b9"} -->
                        <div
                          class="wp-block-ugb-container ugb-container ugb-3c999b9 ugb-container--v2 ugb-container--design-basic ugb-container--width-small ugb-main-block"
                          id="">
                          <div class="ugb-inner-block">
                            <div class="ugb-block-content">
                              <div class="ugb-container__wrapper ugb-3c999b9-wrapper">
                                <div class="ugb-container__side">
                                  <div class="ugb-container__content-wrapper ugb-3c999b9-content-wrapper">
                                    <!-- wp:image {"id":1585,"width":1024,"height":498,"sizeSlug":"large"} -->
                                    <figure class="wp-block-image size-large is-resized"><img
                                        src="../../pics/Loxone-Konfiguration-Wartungsintervalle-2-1024x498.png" alt=""
                                        class="wp-image-1585" width="100%" height="100%" /></figure>
                                    <!-- /wp:image -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- /wp:ugb/container -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /wp:ugb/accordion -->

            <!-- wp:spacer -->
            <div style="height:100px" aria-hidden="true" class="wp-block-spacer"></div>
            <!-- /wp:spacer -->

            <!-- wp:heading {"level":3} -->
            <h3>Hilfreiche Links</h3>
            <!-- /wp:heading -->

            <!-- wp:list -->
            <ul>
              <li><a href="http://kundenportal.sidanet.at">Kundenportal</a></li>
              <li><a href="https://www.loxone.com/dede/kb/">Loxone Dokumentation</a></li>
              <li><a href="https://www.loxwiki.eu/display/LOX/Loxone+Community+Wiki">Loxwiki Dokumentation</a></li>
              <li><a href="https://kundenportal.at/shop/productprice">Produktpreise</a></li>
            </ul>
            <!-- /wp:list -->

            <!-- wp:spacer -->
            <div style="height:100px" aria-hidden="true" class="wp-block-spacer"></div>
            <!-- /wp:spacer -->

            <!-- wp:spacer -->
            <div style="height:100px" aria-hidden="true" class="wp-block-spacer"></div>
            <!-- /wp:spacer -->



          </div>
        </div>
      </div>
    </div>

    <div class="site-section overlay site-cover-2"
      style="background-image: url('pics/DSC01854-2.jpg');filter: grayscale(90%);">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 mx-auto text-center">
            <!-- <h2 class="text-white mb-4">Guter Rat muss nicht immer teuer sein...</h2> -->
            <h2 class="text-white mb-4">Heben Sie ihr Smarthome auf ein neues Level</h2>

            <!-- <p class="mb-0"><a href="https://untree.co/" rel="noopener" class="btn btn-primary">Get it for free!</a></p> -->
          </div>
        </div>
      </div>
    </div>








  </main>


</template>


<script>
export default {
  computed: {
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.closeMenu();
  },
  methods: {
    closeMenu() {
      console.log("close menu");
      var toggler = document.getElementById('navbarCollapse');
      console.log(toggler);
      try {
        toggler.classList.remove('hide');
      } catch (error) {
        toggler.classList.remove('show');
      } 

    },
  }
};
</script>