<template>
  <header>
    <nav class="navbar navbar-expand-md bg-secondary-subtle fixed-top">


      <div class="container">
        <router-link to="/" class="d-inline-flex link-body-emphasis" style="">
          <img src="../../Sidanet-logo-Text2-e1570884482296.png" href="index.html" alt="Image" class="img-fluid" style="width: 90px;">
        </router-link>
        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse collapse" id="navbarCollapse" style="">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            
            <!-- <router-link to="/shop" class="btn nav-item" >
              <a class="nav-link" href="#">
                <i class="fa fa-shop"></i>
                 Shop</a>
            </router-link> -->
            

            <li class="nav-item dropdown" style="padding: 6px;">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa fa-store-alt"></i>
                Shop
              </a>
              <ul class="dropdown-menu">
                <router-link to="/shop/productcaller" class="btn nav-item" >
                  <a class="nav-link" href="#">
                    <i class="fa fa-phone"></i>
                    Caller</a>
                </router-link>
                <router-link to="/shop/productbasar" class="btn nav-item" >
                  <a class="nav-link" href="#">
                    <i class="fa fa-store"></i>
                    Basar</a>
                </router-link>
                <router-link to="/shop/productwaitery" class="btn nav-item" >
                  <a class="nav-link" href="#">
                    <i class="fa fa-wine-glass-alt"></i>
                    Waitery</a>
                </router-link>
              </ul>
            </li>

            
            <li class="nav-item dropdown" style="padding: 6px;">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa fa-store-alt"></i>
                Dokumentation
              </a>
              <ul class="dropdown-menu">
                <router-link to="/doku/dokucaller" class="btn nav-item" >
                  <a class="nav-link" href="#">
                    <i class="fa fa-phone"></i>
                    Caller</a>
                </router-link>
              </ul>
            </li>

            <!-- <router-link to="/documentation" class="btn nav-item">
              <a class="nav-link" href="#">
                <i class="fa fa-book"></i>
                 Doku</a>
            </router-link> -->
            

            <li v-if="isAdmin" class="nav-item dropdown" style="padding: 6px;">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa fa-chart-line text-primary"></i>
                Admin
              </a>
              <ul class="dropdown-menu">
                <router-link v-if="isAdmin" to="/admindashboard" class="btn nav-item">
                  <a class="nav-link"  href="#">
                    <i class="fa fa-chart-line text-primary"></i>
                    Dashboard</a>
                </router-link>
                <router-link v-if="isAdmin" to="/customers" class="btn nav-item" >
                  <a class="nav-link" href="#">
                    <i class="fa fa-users text-primary"></i>
                    Customers</a>
                </router-link>
                <router-link v-if="isAdmin" to="/adminabos" class="btn nav-item" >
                  <a class="nav-link" href="#">
                    <i class="fa fa-list text-primary"></i>
                    Abos</a>
                </router-link>
                <router-link v-if="isAdmin" to="/phonehistory" class="btn nav-item">
                  <a class="nav-link" href="#">
                    <i class="fa fa-clock-rotate-left text-primary"></i>
                    History</a>
                </router-link>
                <router-link v-if="isAdmin" to="/newsfeed" class="btn nav-item">
                  <a class="nav-link" href="#">
                    <i class="far fa-newspaper text-primary"></i>
                    News</a>
                </router-link>
              </ul>
            </li>
            <li v-if="isLoggedin" class="nav-item dropdown" style="padding: 6px;">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa fa-user text-primary"></i>
                Caller Management
              </a>
              <ul class="dropdown-menu">
                <router-link v-if="isLoggedin" to="/profile" class="btn nav-item" >
                  <a class="nav-link"  href="#">
                    <i class="fa fa-user text-primary"></i>
                    Profil</a>
                </router-link>
                
                <router-link v-if="isLoggedin" to="/abos" class="btn nav-item" >
                  <a class="nav-link"  href="#">
                    <i class="fa fa-bars text-primary"></i>
                    Abo</a>
                </router-link>
              </ul>
            </li>

            <!-- <router-link  v-if="isLoggedin" to="/testbuy" class="btn nav-item">
              <a class="nav-link" href="#">
                <i class="fa fa-book"></i>
                 Testbuy</a>
            </router-link> -->
          </ul>
          <div class="navbar-end">
            <div class="navbar-item">
              <div class="buttons">
                <!-- <router-link v-if="isLoggedin" class="btn nav-item btn-secondary"><strong> {{ Username }}</strong></router-link> -->
                <router-link v-if="!isLoggedin" to="/register" class="btn nav-item"><strong><i class="fa fa-arrow-up-from-bracket"></i> Registrieren</strong></router-link>
                <router-link  v-if="!isLoggedin" to="/login" class="btn nav-item btn-outline-primary"><i class="fa fa-right-to-bracket"></i> Login</router-link>
                <router-link v-if="isLoggedin"  to="/profile" class="btn nav-item btn-lg">{{ Username }}</router-link>
                <button v-if="isLoggedin" @click="logoutButton" class="btn nav-item text-danger"><i class="fa fa-right-to-bracket"></i> </button>

              </div>
            </div>
          </div>
        </div>
      </div>



    </nav>
  </header>
</template>





<script>
export default {
  computed: {
            Username() {
              return localStorage.getItem('userN');
            },
            isLoggedin() {
                return this.$store.getters.isAuthenticated;
            },
            isAdmin() {
              return this.$store.getters.isAdmin;
            },
        },
    created() {
      console.log("do hide now !");
    },
    methods: {
      logoutButton() {
            this.$store.dispatch('logout');
            this.$router.replace('/login');
            console.log('isAuth: ' + this.$store.getters.isAuthenticated);
        },
    },
}
</script>
