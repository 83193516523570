import  axios   from 'axios';

export default {
    async getnewsdata(context,payload) {
        // console.log(context,payload);
        try {
            const httplink = context.state.serverlink+'newsfeed.php?key=9999';
            // console.log(payload);
            const response = await axios.post(httplink, {
            userid: payload.userid,
            token: payload.token,
            limit: payload.limit,
            });

            console.log(response.data);

            const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
            console.log(parsedResponse);
            
            const newsdata = [];

            for (const key in parsedResponse) {
                if (parsedResponse[key].id != null){
                    const newsline = {
                        id: parsedResponse[key].id,
                        title: parsedResponse[key].title,
                        Benutzer_id: parsedResponse[key].Benutzer_id,
                        maintext: parsedResponse[key].maintext,
                        erstellt: parsedResponse[key].erstellt,
                    };
                    newsdata.push(newsline);
                }
                
              }

            context.commit('setNewsData', newsdata);
            console.log(context);

        } catch (error) {
            console.error(error);
        }
    },
    
    async addNews(context,payload) {
        console.log('we adding news!');

        console.log(payload.newsTitle);
        const httplink = context.state.serverlink+'addNews.php?key=9999';
        console.log(httplink);
        const response = await axios.post(httplink, {
        userId: payload.userid,
        sessiontoken: payload.token,
        title: payload.title,
        maintext: payload.maintext,
        });


        const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
        console.log(parsedResponse);
        const line = {
                    status: parsedResponse["status"],
                    message: parsedResponse["message"],
                };
        if(line.status == 'error') {
            console.log('error throwed: '+ line.message);
            throw new Error(line.message);
        }
        // console.log(context);


    }

};